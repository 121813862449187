<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="headers"
                    :items="filters"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    calculate-widths
                    show-expand
                    @click:row="openLead"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                            style="background-color: #eeeeee !important"
                            :height="
                                $vuetify.breakpoint.smAndDown ? '100%' : '65%'
                            "
                            class="round-data-table pr-0"
                        >
                            <v-col
                                class="mt-5 pt-5 pt-sm-3 pt-md-3 pt-lg-3 pt-xl-3"
                                cols="9"
                                sm="7"
                                md="7"
                                lg="9"
                                xl="9"
                            >
                                <v-row
                                    ><h2 class="ml-4 mr-3">LEADS</h2>
                                    <v-menu
                                        v-if="
                                            ruta == 'rejected' ||
                                                $router.currentRoute.name ==
                                                    'leads-rejected'
                                        "
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                v-model="dateRangeText"
                                                label="Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                style="max-width: 250px"
                                                class="pt-0"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dates"
                                            range
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menu = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                :loading="loading"
                                                @click="getLeads"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    <v-spacer
                                /></v-row>
                            </v-col>
                            <v-spacer />
                            <v-btn
                                v-if="
                                    $vuetify.breakpoint.mdAndUp &&
                                        (ruta == 'inProgress' ||
                                            $router.currentRoute.name ==
                                                'leads-inprogress')
                                "
                                rounded
                                depressed
                                color="primary"
                                class="white--text"
                                @click="openCreateDialog"
                                hide-details
                            >
                                <v-icon left>mdi-plus</v-icon>
                                <span>New</span>
                            </v-btn>
                            <v-btn
                                v-if="
                                    $vuetify.breakpoint.smAndDown &&
                                        (ruta == 'inProgress' ||
                                            $router.currentRoute.name ==
                                                'leads-inprogress')
                                "
                                fab
                                small
                                class="primary--text"
                                color="white"
                                @click="openCreateDialog"
                            >
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>

                    <!-- Headers -->
                    <template v-slot:expanded-item="{ headers, item }">
                        <td
                            :colspan="headers.length"
                            class="py-4 pr-4 pl-12  ma-0 outlined"
                            style="background: white"
                        >
                            <LeadsCosts
                                :lead="item"
                                :showTitle="false"
                                :user="user"
                                :users="users"
                            />
                        </td>
                    </template>
                    <template v-slot:[`header.name`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="nameToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template v-slot:[`header.clientName`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="clientToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template v-slot:[`header.accountManager`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="AManagerToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template v-slot:[`header.collaborators`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="collaboratorToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template v-slot:[`header.country`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="countryToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template v-slot:[`header.city`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="cityToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template v-slot:[`header.requestDateShow`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="requestDateToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template v-slot:[`header.invitationDateShow`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="invitationDateToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template v-slot:[`header.createdOn`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="createdOnToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template v-slot:[`header.status`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="statusToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <template
                        v-if="ruta != 'rejected'"
                        v-slot:[`header.action`]="{ header }"
                    >
                        <h3>{{ header.text }}</h3>
                    </template>

                    <template v-slot:[`header.pending`]="{ header }">
                        <h3>{{ header.text }}</h3>
                    </template>

                    <!-- Items -->
                    <template
                        v-slot:[`item.data-table-expand`]="{
                            item,
                            expand,
                            isExpanded,
                        }"
                    >
                        <td
                            v-if="item.costs && item.costs.length > 0"
                            class="text-start"
                        >
                            <v-btn
                                icon
                                @click.stop="expand(!isExpanded)"
                                class="v-data-table__expand-icon"
                                :class="{
                                    'v-data-table__expand-icon--active': isExpanded,
                                }"
                            >
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </td>
                    </template>
                    <template v-slot:[`item.accountManager`]="{ item }">
                        <div dense class="pt-2">
                            {{ item.accountManager }}
                        </div>
                    </template>

                    <template v-slot:[`item.clientName`]="{ item }">
                        <div class="my-4">
                            {{ item.clientName }}
                        </div>
                    </template>

                    <template v-slot:[`item.collaborators`]="{ item }">
                        <div class="my-4">
                            <p
                                v-for="collaborator of item.collaborators"
                                :key="collaborator"
                            >
                                {{ getUserName(collaborator) }}
                            </p>
                        </div>
                    </template>

                    <template v-slot:[`item.createdOn`]="{ item }">
                        <div class="my-4">
                            {{
                                item.createdOn
                                    ? formatDate(
                                          item.createdOn._seconds ||
                                              item.createdOn.seconds
                                      )
                                    : ''
                            }}
                        </div>
                    </template>

                    <template v-slot:[`item.pending`]="{ item }">
                        <v-btn
                            v-if="pendingForReview(item) > 0"
                            small
                            icon
                            class="mr-1"
                            color="black"
                            style="background-color: #ffc400;"
                            disabled
                        >
                            <p class="my-0" style="color: black;">
                                {{ item.costs.length }}
                            </p>
                        </v-btn>
                        <p
                            v-else-if="item.costs.length > 0"
                            class="my-0"
                            style="color: black;"
                        >
                            {{ item.costs.length }}
                        </p>
                    </template>
                    <template
                        v-slot:[`item.action`]="{ item }"
                        v-if="ruta != 'rejected' || 'approved'"
                    >
                        <div class="d-flex justify-center ">
                            <v-menu rounded offset-y>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        depressed
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        v-if="
                                            item.status == 'inProgress' ||
                                                item.status == 'onHold'
                                        "
                                        @click.stop="openCreateItem(item)"
                                    >
                                        <v-list-item-title>
                                            New Scope
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click.stop="editLead(item)">
                                        <v-list-item-title>
                                            Edit
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            item.status == 'approved' ||
                                                item.status == 'onHold'
                                        "
                                        @click.stop="
                                            sendToInProgressAlert(item)
                                        "
                                    >
                                        <v-list-item-title>
                                            Active
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="item.status != 'onHold'"
                                        @click.stop="sendToOnHoldAlert(item)"
                                    >
                                        <v-list-item-title>
                                            On Hold
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            item.status == 'inProgress' ||
                                                item.status == 'onHold'
                                        "
                                        @click.stop="sendToApproveAlert(item)"
                                    >
                                        <v-list-item-title>
                                            Approve
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="item.status == 'approved'"
                                        @click.stop="openSetPromiseForm(item)"
                                    >
                                        <v-list-item-title>
                                            For Bidding
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item
                                        v-if="item.status != 'rejected'"
                                        @click.stop="sendToRejectedAlert(item)"
                                    >
                                        <v-list-item-title>
                                            Reject
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item
                                        @click.stop="deleteAlert(item)"
                                    >
                                        <v-list-item-title>
                                            Delete
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!-- Set Promise Date -->
        <v-dialog
            v-model="setPromiseDate"
            :retain-focus="false"
            persistent
            max-width="400"
        >
            <v-card :loading="loading" v-if="setPromiseDate">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeSetPromiseDate"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        SEND FOR BIDDING
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    <v-form>
                        <v-row no-gutters>
                            <v-col cols="12" class="pt-4">
                                <v-menu
                                    ref="menu"
                                    :close-on-content-click="true"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-combobox
                                            class="py-0 my-0"
                                            v-model="promiseDate"
                                            color="primary"
                                            label="Promise Date"
                                            readonly
                                            flat
                                            hide-details
                                            v-bind="attrs"
                                            v-on="on"
                                            prepend-icon="mdi-calendar-blank-outline"
                                            :rules="[rules.required]"
                                        ></v-combobox>
                                    </template>
                                    <v-date-picker
                                        v-model="promiseDate"
                                        scrollable
                                        :min="minDate"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="estimatorId"
                                    :loading="loading"
                                    :items="users"
                                    item-text="longName"
                                    item-value="id"
                                    prepend-icon="mdi-account-cash"
                                    label="Estimator *"
                                    required
                                    :rules="[rules.required]"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="sendQuote"
                        color="primary"
                        :loading="loading"
                        rounded
                        :disabled="!promiseDate || !estimatorId"
                    >
                        CREATE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Create New Item Cost Detail  -->
        <v-dialog
            v-model="createItem"
            width="500px"
            persistent
            :retain-focus="false"
        >
            <LeadCostsForm
                v-if="createItem"
                :lead="selectedLead"
                :title="title"
                :createForm="createForm"
                :originalCost="newItemCost"
                @closeDialog="closeFormCost"
                @addCost="addCost"
                @replaceCost="replaceCost"
            />
        </v-dialog>
        <!--Lead General Info-->
        <v-dialog
            :retain-focus="false"
            v-model="openLeadDialog"
            fullscreen
            persistent
        >
            <v-card v-if="openLeadDialog">
                <v-card-text style="height:100%">
                    <LeadsGeneralInfo
                        :id="selectedLead.id"
                        :lead="selectedLead"
                        :settings="settings"
                        :clients="clients"
                        :user="user"
                        :users="users"
                        :action="action"
                        @setPromiseDate="openSetPromiseForm"
                        @closeLead="closeLead"
                        @removeLead="removeLead"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--Send to another state-->
        <v-dialog
            v-model="sendWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="sendWarning">
                <v-card-title
                    v-if="onHold || approve || inProgress"
                    class="text-h5"
                    >Send Request</v-card-title
                >
                <v-card-title v-if="reject" class="text-h5"
                    >Reject Request</v-card-title
                >
                <v-card-title v-if="toDelete" class="text-h5"
                    >Delete Request</v-card-title
                >
                <v-card-text v-if="onHold">
                    Are you sure you want to send to On Hold?
                </v-card-text>
                <v-card-text v-if="reject">
                    Are you sure you want to reject the request?
                </v-card-text>
                <v-card-text v-if="approve">
                    Are you sure you want to send to Approve?
                </v-card-text>
                <v-card-text v-if="inProgress">
                    Are you sure you want to send to Active?
                </v-card-text>
                <v-card-text v-if="toDelete">
                    Are you sure you want to delete the request?
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="secondary" @click="closeAlert">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="onHold || inProgress"
                        @click="sendToAnotherStatus"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Send
                    </v-btn>
                    <v-btn
                        v-if="approve"
                        @click="sendToApprove"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Send
                    </v-btn>
                    <v-btn
                        v-if="reject"
                        @click="sendToRejected"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Reject
                    </v-btn>
                    <v-btn
                        v-if="toDelete"
                        @click="deleteLead"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div>
            <v-col>
                <v-dialog
                    :retain-focus="false"
                    v-model="createDialog"
                    persistent
                    max-width="500px"
                >
                    <QuoteForm
                        v-if="createDialog"
                        :title="title"
                        :originalLead="selectedLead"
                        :createForm="createForm"
                        @closeDialog="closeCreateDialog"
                        :settings="settings"
                        @addLead="addLead"
                        @updateLead="updateLead"
                    />
                </v-dialog>
            </v-col>
        </div>
    </v-container>
</template>

<script>
import API from '@/services/api'
import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
    name: 'Leads',
    props: {
        ruta: { type: String },
    },
    components: {
        QuoteForm: () => import('@/components/Quotes/QuoteForm.vue'),
        LeadsGeneralInfo: () =>
            import('@/components/Leads/LeadsGeneralInfo.vue'),
        LeadsCosts: () => import('@/components/Leads/LeadsCosts.vue'),
        LeadCostsForm: () => import('@/components/Leads/LeadCostsForm.vue'),
    },
    data: () => ({
        collaboratorToFilter: '',
        headers: [
            { text: '', value: 'data-table-expand', sortable: false },
            {
                text: 'Name',
                value: 'name',
                align: 'center',
                sortable: false,
                width: 100,
            },
            {
                text: 'Client',
                value: 'clientName',
                align: 'center',
                sortable: false,
                width: 100,
            },
            {
                text: 'Account M.',
                value: 'accountManager',
                align: 'center',
                sortable: false,
                width: 100,
            },
            {
                text: 'Collaborators',
                value: 'collaborators',
                align: 'center',
                sortable: false,
                width: 100,
            },
            {
                text: 'Country',
                value: 'country',
                align: 'center',
                sortable: false,
                width: 60,
            },
            {
                text: 'City',
                value: 'city',
                align: 'center',
                sortable: false,
                width: 60,
            },
            {
                text: 'Invitation Date',
                value: 'invitationDateShow',
                align: 'center',
                sortable: false,
                width: 100,
            },
            {
                text: 'Due Date',
                value: 'requestDateShow',
                align: 'center',
                sortable: false,
                width: 100,
            },
            {
                text: 'Created On',
                value: 'createdOn',
                align: 'center',
                sortable: false,
                width: 110,
            },
            {
                text: 'Status',
                value: 'status',
                align: 'center',
                sortable: false,
                width: 100,
            },
        ],
        height: 0,
        loading: false,
        items: [],
        createDialog: false,
        id: '',
        users: [],
        nameToFilter: '',
        clientToFilter: '',
        AManagerToFilter: '',
        countryToFilter: '',
        cityToFilter: '',
        requestDateToFilter: '',
        invitationDateToFilter: '',
        createdOnToFilter: '',
        leads: [],
        folderLeads: 'leads_documents',
        companyId: JSON.parse(localStorage.getItem('company')),
        setPromiseDate: false,
        promiseDate: '',
        lead: '',
        openLeadDialog: false,
        createForm: false,
        createItem: false,
        newItemCost: {
            measure: 'ft2',
        },
        title: '',
        onHold: false,
        reject: false,
        sendWarning: false,
        statusToFilter: '',
        selectedLead: {},
        selectedItem: {},
        user: {},
        minDate: '',
        leadsOnHold: [],
        leadsInProgress: [],
        listener: undefined,
        menu: false,
        dates: [],
        action: { active: false },
        approve: false,
        inProgress: false,
        rules: {
            required: v => !!v || 'Required',
        },
        estimatorId: '',
        toDelete: false,
    }),
    computed: {
        ...mapState(['settings']),
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        filters() {
            let conditions = []

            if (this.nameToFilter) {
                conditions.push(this.filterForName)
            }
            if (this.clientToFilter) {
                conditions.push(this.filterForClient)
            }
            if (this.AManagerToFilter) {
                conditions.push(this.filterForAManager)
            }
            if (this.countryToFilter) {
                conditions.push(this.filterForCountry)
            }
            if (this.cityToFilter) {
                conditions.push(this.filterForCity)
            }
            if (this.requestDateToFilter) {
                conditions.push(this.filterForRequestDate)
            }
            if (this.invitationDateToFilter) {
                conditions.push(this.filterForInvitationDate)
            }
            if (this.createdOnToFilter) {
                conditions.push(this.filterForCreatedOn)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }
            if (this.collaboratorToFilter) {
                conditions.push(this.filterForCollaborator)
            }

            if (conditions.length > 0) {
                return this.leads.filter(lead => {
                    return conditions.every(condition => {
                        return condition(lead)
                    })
                })
            }
            if (this.projectCity) {
                conditions.push(this.filterCity)
            }
            if (this.projectCollaborator) {
                conditions.push(this.filterCollaborators)
            }
            if (conditions.length > 0) {
                return this.leads.filter(lead => {
                    return conditions.every(condition => {
                        return condition(lead)
                    })
                })
            }
            this.sort()
            return this.leads
        },
    },
    watch: {
        async ruta() {
            if (
                this.ruta != 'rejected' &&
                !this.headers.some(key => key.text == 'Action')
            ) {
                this.headers.push({
                    text: 'Pending',
                    value: 'pending',
                    align: 'center',
                    sortable: false,
                    width: 50,
                })
                this.headers.push({
                    text: 'Action',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                    width: 60,
                })
            } else if (
                this.ruta == 'rejected' &&
                this.headers.some(key => key.text == 'Action')
            ) {
                this.headers.splice(this.headers.length - 2, 2)
            }
            await this.getLeads()
        },
        action: function() {
            if (this.action.active) {
                setTimeout(() => {
                    this.action.active = false
                }, 5000)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.error = false
            if (this.$router.currentRoute.name != 'leads-rejected') {
                this.headers.push({
                    text: 'Pending',
                    value: 'pending',
                    align: 'center',
                    sortable: false,
                    width: 50,
                })
                this.headers.push({
                    text: 'Action',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                    width: 60,
                })
            }
            const {
                data: { clients },
            } = await API.getClients()
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.users.forEach(
                user => (user.longName = `${user.name} (${user.role})`)
            )
            this.clients = clients
            //set initial dates
            let currentDate = new Date()
            let date1 = new Date(currentDate)
            date1.setMonth(date1.getMonth() - 1)
            date1.setHours(0, 0, 0, 0)
            this.dates[0] = date1.toISOString().split('T')[0]
            this.dates[1] = currentDate.toISOString().split('T')[0]
            //get data
            await this.getLeads()
            const {
                data: { user },
            } = await API.getMyInfo()
            this.minDate = new Date().toISOString()
            this.user = user
            this.errorMessage = ''
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    updated() {
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        removeLead(leadId) {
            try {
                this.leads = this.leads.filter(lead => lead.id != leadId)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        filterForName(item) {
            if (item.name) {
                return item.name
                    .toLowerCase()
                    .includes(this.nameToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForClient(item) {
            if (item.clientName) {
                return item.clientName
                    .toLowerCase()
                    .includes(this.clientToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForAManager(item) {
            if (item.accountManager) {
                return item.accountManager
                    .toLowerCase()
                    .includes(this.AManagerToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCountry(item) {
            if (item.city) {
                return item.country
                    .toLowerCase()
                    .includes(this.countryToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCity(item) {
            if (item.city) {
                return item.city
                    .toLowerCase()
                    .includes(this.cityToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForRequestDate(item) {
            if (item.requestDate) {
                const date = this.formatDate2(
                    item.requestDate._seconds || item.requestDate.seconds
                )
                return date.includes(this.requestDateToFilter)
            } else {
                return false
            }
        },
        filterForInvitationDate(item) {
            if (item.invitationDate) {
                const date = this.formatDate2(
                    item.invitationDate._seconds || item.invitationDate.seconds
                )
                return date.includes(this.invitationDateToFilter)
            } else {
                return false
            }
        },
        filterForCreatedOn(item) {
            if (item.createdOn) {
                const date = this.formatDate2(
                    item.createdOn._seconds || item.createdOn.seconds
                )
                return date.includes(this.createdOnToFilter)
            } else {
                return false
            }
        },
        filterForStatus(item) {
            if (item.status) {
                return item.status
                    .toLowerCase()
                    .includes(this.statusToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCollaborator(item) {
            if (item.collaborators && item.collaborators.length > 0) {
                return !!item.collaborators.find(colab =>
                    this.getUserName(colab)
                        .toLowerCase()
                        .includes(this.collaboratorToFilter.toLowerCase())
                )
            } else {
                return false
            }
        },
        sort() {
            this.leads.sort(
                (a, b) => b.updatedOn._seconds - a.updatedOn._seconds
            )
        },
        closeAlert() {
            this.selectedItem = {}
            this.sendWarning = false
            if (this.onHold) {
                this.onHold = false
            }
            if (this.approve) {
                this.approve = false
            }
            if (this.reject) {
                this.reject = false
            }
            if (this.inProgress) {
                this.inProgress = false
            }
            if (this.toDelete) {
                this.toDelete = false
            }
        },
        formatDate2(seconds) {
            return moment.unix(seconds).format('YYYY/MM/DD')
        },

        sendToOnHoldAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.onHold = true
            this.sendWarning = true
        },
        sendToInProgressAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.inProgress = true
            this.sendWarning = true
        },
        sendToApproveAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.approve = true
            this.sendWarning = true
        },

        sendToRejectedAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.reject = true
            this.sendWarning = true
        },
        deleteAlert(item) {
            this.selectedItem = _.cloneDeep(item)
            this.toDelete = true
            this.sendWarning = true
        },
        async sendToAnotherStatus() {
            try {
                this.loading = true
                if (this.onHold) {
                    this.selectedItem.status = 'onHold'
                } else if (this.inProgress) {
                    this.selectedItem.status = 'inProgress'
                }

                const lead = await API.updateLead({
                    id: this.selectedItem.id,
                    status: this.selectedItem.status,
                })
                this.updateLead(lead)
                this.closeAlert()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async sendToApprove() {
            try {
                this.loading = true
                if (
                    this.selectedItem.status == 'inProgress' ||
                    this.selectedItem.status == 'onHold'
                ) {
                    this.selectedItem.status = 'approved'
                }
                const lead = await API.approveLead({
                    id: this.selectedItem.id,
                })
                this.updateLead(lead)
                this.closeAlert()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async sendToRejected() {
            try {
                this.loading = true
                await API.rejectLead({
                    id: this.selectedItem.id,
                })
                this.removeLead(this.selectedItem.id)
                this.closeAlert()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteLead() {
            try {
                this.loading = true
                await API.deleteLead({
                    id: this.selectedItem.id,
                })
                this.removeLead(this.selectedItem.id)
                this.closeAlert()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openCreateDialog() {
            this.title = 'New Lead'
            this.createDialog = true
            this.createForm = true
        },
        editLead(item) {
            this.selectedLead = _.cloneDeep(item)
            this.title = 'Edit Lead'
            this.createForm = false
            this.createDialog = true
        },
        closeCreateDialog() {
            this.createForm = false
            this.createDialog = false
            this.selectedLead = {}
        },
        openSetPromiseForm(item) {
            this.setPromiseDate = true
            this.lead = _.cloneDeep(item)
        },
        closeSetPromiseDate() {
            this.promiseDate = ''
            this.estimatorId = ''
            this.setPromiseDate = false
        },
        async sendQuote() {
            this.lead.promiseDate = this.promiseDate
            this.lead.estimatorId = this.estimatorId
            if (this.lead != undefined) {
                await this.createQuote(this.lead)
            }
        },
        async closeLead() {
            try {
                this.selectedLead = {}
                this.openLeadDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async openLead(item) {
            try {
                this.heightPopUp = window.innerHeight + 65
                this.selectedLead = this.leads.filter(
                    lead => lead.id == item.id
                )
                this.selectedLead = this.selectedLead[0]
                this.openLeadDialog = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        createQuote: async function(item) {
            try {
                this.loading = true
                // move lead files to quote files
                await API.moveLeadFiles({
                    id: item.id,
                    sourceFolder: `${this.companyId}/${this.folderLeads}/${item.id}/`,
                    destinationFolder: `${this.companyId}/quotes_documents/${item.id}/`,
                })
                item.requestDate = new Date(
                    item.requestDate._seconds * 1000
                ).toISOString()
                item.invitationDate = new Date(
                    item.invitationDate._seconds * 1000
                ).toISOString()

                await API.forBidding({
                    promiseDate: item.promiseDate,
                    leadId: item.id,
                    estimatorId: item.estimatorId,
                })
                this.leads = this.leads.filter(l => l.id != item.id)
                if (this.openLeadDialog) {
                    this.closeLead()
                }
                this.promiseDate = null
                this.estimatorId = null
                this.setPromiseDate = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                this.action = {
                    active: true,
                    type: 'error',
                    message: 'Current lead has no scope',
                    color: 'error',
                }
            } finally {
                this.loading = false
            }
        },
        getLeads: async function() {
            try {
                this.loading = true
                this.leads = []
                this.menu = false
                let date1 = new Date(this.dates[0]).getTime()
                let date2 = new Date(this.dates[1]).getTime()
                if (date1 > date2) {
                    date1 = this.dates[1]
                    date2 = this.dates[0]
                } else {
                    date1 = this.dates[0]
                    date2 = this.dates[1]
                }
                if (
                    (this.ruta == 'rejected' ||
                        this.$router.currentRoute.name == 'leads-rejected') &&
                    this.leads.length == 0
                ) {
                    this.leads = await API.getRejectedLeads({
                        startDate: date1,
                        endDate: date2,
                    })
                } else if (
                    (this.ruta == 'approved' ||
                        this.$router.currentRoute.name == 'leads-approved') &&
                    this.leads.length == 0
                ) {
                    this.leads = await API.getApprovedLeads()
                    this.leads.forEach(lead => {
                        if (lead.bidding == undefined) {
                            lead.bidding = true
                        }
                    })
                } else if (
                    this.ruta == 'inProgress' ||
                    this.$router.currentRoute.name == 'leads-inprogress'
                ) {
                    this.leads = await API.getLeads({ status: 'inProgress' })
                } else if (
                    this.ruta == 'onHold' ||
                    this.$router.currentRoute.name == 'leads-onhold'
                ) {
                    this.leads = await API.getLeads({ status: 'onHold' })
                }
                for (const lead of this.leads) {
                    lead.costs = !lead.costs ? [] : lead.costs
                    lead.attached = lead.files.filter(x => x.attach == true)
                    lead.docs = lead.files.filter(x => x.attach == false)

                    if (lead.createdOn && lead.createdOn._seconds) {
                        lead.sequence = lead.createdOn._seconds
                        if (!lead.number) {
                            lead.shortId = lead.createdOn._seconds
                                .toString(16)
                                .toUpperCase()
                        }
                    } else {
                        lead.sequence = 0
                    }
                    lead.requestDateShow = lead.requestDate
                        ? this.formatDate(lead.requestDate._seconds)
                        : ''
                    lead.invitationDateShow = lead.invitationDate
                        ? this.formatDate(lead.invitationDate._seconds)
                        : ''
                    lead.clientName = lead.clientId
                        ? this.getClientName(lead.clientId)
                        : ''
                    lead.accountManager = lead.userId
                        ? this.getUserName(lead.userId)
                        : ''
                    lead.costs.forEach(cost => {
                        cost.engineeringDate =
                            cost.engineeringDate && cost.engineeringDate != null
                                ? (cost.engineeringDate = this.formatDate3(
                                      cost.engineeringDate._seconds
                                  ))
                                : ''
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getUserName(id) {
            if (this.users.length > 0) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')}`
        },
        formatDate3(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        onResize() {
            this.height = window.innerHeight - 112
            this.heightPopUp = window.innerHeight - 62
        },
        openCreateItem(item) {
            this.selectedLead = _.cloneDeep(item)
            this.title = 'New Cost'
            this.createForm = true
            this.createItem = true
        },
        closeFormCost() {
            this.createItem = false
            this.createForm = false
        },
        addLead: function(lead) {
            lead.clientName = lead.clientId
                ? this.getClientName(lead.clientId)
                : ''
            lead.accountManager = lead.collaborators
                ? this.getUserName(lead.collaborators[0])
                : ''

            this.leads.push(lead)
        },
        updateLead(lead) {
            let index = this.leads.findIndex(leads => leads.id === lead.id)
            if (
                lead.status == 'onHold' ||
                lead.status == 'approved' ||
                lead.status == 'inProgress'
            ) {
                this.leads = this.leads.filter(l => l.id != lead.id)
                return null
            }
            if (lead.invitationDate) {
                lead.invitationDateShow = this.formatDate(
                    lead.invitationDate._seconds
                )
            }
            if (lead.requestDate) {
                lead.requestDateShow = this.formatDate(
                    lead.requestDate._seconds
                )
            }
            if (index !== -1) {
                this.leads.splice(index, 1, {
                    ...this.leads[index],
                    ...lead,
                })
            }
        },
        addCost(cost) {
            const index = this.leads.findIndex(
                l => l.id == this.selectedLead.id
            )
            if (this.leads[index].costs) {
                this.leads[index].costs.push(cost)
            } else {
                this.leads[index].costs = [cost]
            }
            this.leads = _.cloneDeep(this.leads)
            this.closeFormCost()
        },
        replaceCost(cost) {
            const leadIndex = this.leads.findIndex(
                l => l.id == this.selectedLead.id
            )
            const itemIndex = this.leads[leadIndex].costs.findIndex(
                c => c.id == this.newItemCost.id
            )
            this.leads[leadIndex].costs.splice(itemIndex, 1, cost)
            this.closeFormCost()
        },
        pendingForReview(item) {
            let pending = 0
            if (item.costs && item.costs.length > 0) {
                item.costs.forEach(cost => {
                    if (cost.reviewer == this.user.id && !cost.reviewed) {
                        pending++
                    }
                })
            }
            return pending
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
thead {
    background-color: #eeeeee;
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
